<template>
    <workspace-wrapper>
        <template #header>
            <default-slot-no-lines-header :bet="stateData.currentSpinResult.bet"
                                          :stake="stateData.currentSpinResult.stake"
            />
            <free-spins-slot-header v-if="stateData.currentSpinResult.isFreeSpin"
                                    v-bind="stateData.currentSpinResult.bonusFreeSpins"
            />
        </template>

        <template #workspace>
            <multi-layout-workspace :layouts="layouts"
                                    :symbols-path="symbolsPath"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../Common/WorkspaceWrapper";
import DefaultSlotNoLinesHeader from "./Common/Header/DefaultSlotNoLinesHeader";
import FreeSpinsSlotHeader from "./Common/Header/FreeSpinsSlotHeader";
import MultiLayoutWorkspace from "./Common/MultiLayoutWorkspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        balanceBefore: null,
                        bet: null,
                        linesCount: null,
                        stake: null,
                        layout: null,
                        isFreeSpin: null,
                        subResults: [{
                            layout: null,
                        }],
                        bonusFreeSpins: {
                            totalWin: null,
                            totalFreeSpins: null,
                            currentFreeSpin: null,
                            freeSpinsAwardedCount: null
                        }
                    }
                }
            }
        }
    },
    computed: {
        layouts() {
            return this.stateData.currentSpinResult.subSpinResults.map(result => result.layout);
        }
    },
    data() {
        return {
            symbolsPath: 'sweetbonanza'
        }
    },
    components: {
        WorkspaceWrapper,
        DefaultSlotNoLinesHeader,
        MultiLayoutWorkspace,
        FreeSpinsSlotHeader
    }
}
</script>

<style scoped>

</style>
